import { useState, useEffect } from 'react';
import { formatComponentIds } from '../utils/formatComponentIds';
import { getVariationsFromDE } from '../utils/getVariationsFromDE';
import { getVariationsFromAdobe } from '../utils/getVariationsFromAdobe';
import { getVariationsFromInnervate } from '../utils/getVariationsFromInnervate';

export const useVariationIds = ({
  mboxes = [],
  campaignIds = [],
  isReady = true,
  channel,
  isConsumerApp,
  isSeoBot,
  innervateTags = [],
  storeId = '8991',
  slug,
  isDebug = false,
}) => {
  const [variationIds, setVariationIds] = useState(null);
  const [renderDefault, setRenderDefault] = useState(false);

  useEffect(() => {
    const getVariationIds = async () => {
      if (!variationIds && isReady) {
        try {
          const handleAdobe = async () => {
            const hasMboxes = mboxes.length > 0;
            try {
              if (hasMboxes) {
                const adobeIds = await getVariationsFromAdobe(mboxes);
                return adobeIds;
              }

              return [];
            } catch (error) {
              /* eslint-disable-next-line no-console, no-unused-expressions */
              window.isDebugMode && console.error(error);

              window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.adobeError', {
                error: { message: error?.message, stack: error?.stack },
              });
              return [];
            }
          };

          const handleDE = async () => {
            const hasCampaignIds = campaignIds.length > 0;
            try {
              if (hasCampaignIds) {
                const deIds = await getVariationsFromDE({ campaignIds, channel, isConsumerApp });
                return deIds;
              }

              return [];
            } catch (error) {
              /* eslint-disable-next-line no-console, no-unused-expressions */
              window.isDebugMode && console.error(error);

              window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.deError', {
                error: { status: error?.errorResponse?.status, message: error?.errorMessage },
              });
              return [];
            }
          };

          const handleInnervate = async () => {
            const hasInnervateTags = innervateTags.length > 0;
            try {
              if (hasInnervateTags) {
                const innervateIds = await getVariationsFromInnervate({ innervateTags, storeId, slug, isDebug });
                return innervateIds;
              }

              return [];
            } catch (error) {
              /* eslint-disable-next-line no-console, no-unused-expressions */
              window.isDebugMode && console.error(error);

              window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.innervateError', {
                error: { status: error?.errorResponse?.status, message: error?.errorMessage },
              });
              return [];
            }
          };

          // FIXME: This no longer works with slots/slot numbers
          const query = new URLSearchParams(window.location.search);
          const idsFromQuery = query.get('variationIds');
          const urlVariationIds = idsFromQuery ? idsFromQuery.split(',') : [];

          const [adobeVariationIds, decisionEngineVariationIds, innervateVariationIds] = await Promise.all([
            handleAdobe(),
            handleDE(),
            handleInnervate()
          ]);

          const allIds = formatComponentIds({
            adobeVariationIds,
            decisionEngineVariationIds,
            innervateVariationIds,
            urlVariationIds,
          });

          setVariationIds(allIds);

          if (allIds.length < 1) setRenderDefault(true);

        } catch (error) {
          /* eslint-disable-next-line no-console, no-unused-expressions */
          window.isDebugMode && console.error(error);

          window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.error', { error: error.message });
          // Needed to show the default if some kind of error occurs
          setRenderDefault(true);
        }
      }
    };

    if (!isSeoBot) {
      getVariationIds();
    } else {
      setRenderDefault(true);
    }
  }, [isSeoBot, JSON.stringify(mboxes), JSON.stringify(campaignIds), isReady]);

  return {
    variationIds,
    renderDefault,
  };
};