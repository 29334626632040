import { AnchorLinks } from '@thd-olt-component-react/anchor-links';
import { CalloutCard } from '@thd-olt-component-react/callout_card';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';
import { ComponentSelector, Slot } from '@thd-olt-component-react/component-selector';
import { ContentAccordion } from '@thd-olt-component-react/content-accordion';
import { ContentGalleryCard } from '@thd-olt-component-react/content-gallery-card';
import { ContentSection } from '@thd-olt-component-react/content-section';
import { BrandHero } from '@thd-olt-component-react/hero';
import { Endcap } from '@thd-olt-component-react/endcap';
import { MedioInline, PiccolaPencil } from '@thd-olt-component-react/internal-marketing-banner';
import { NewspaperModule } from '@thd-olt-component-react/newspaper-module';
import { ProductShelf } from '@thd-olt-component-react/product-shelf';
import { Resource } from '@thd-olt-component-react/resource';
import { SideNavigation } from '@thd-olt-component-react/side-navigation';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { SpotlightRichText } from '@thd-olt-component-react/spotlight_rich_text';
import { EmtDescription } from '@thd-olt-component-react/emt-generated-content';
import { PromoVisualNavigation, VisualNavigation } from '@thd-olt-component-react/visual-navigation';

const buildComponentMapperObj = () => {
  const contentfulTypeToComponentMap = {
    AnchorLinks,
    BrandHero,
    CalloutCard,
    CapabilityCard,
    ComponentSelector,
    ContentAccordion,
    Description_New: EmtDescription,
    Gallery: ContentGalleryCard,
    Hero: BrandHero,
    HeroCarousel: ContentSection,
    MedioInline,
    Newspaper: NewspaperModule,
    PiccolaPencil,
    ProductShelf,
    PromoVisualNavigation,
    Resource,
    Section: ContentSection,
    SideNavigation,
    Slot,
    Spotlight,
    SpotlightContainer,
    SpotlightRichText,
    SpotlightRichTextSection: ContentSection,
    VisualNavigation,
    EndCap: Endcap
  };

  const contentfulKeyToContentKeyMap = {
    anchorLinks: 'AnchorLinks',
    CalloutCard: 'CalloutCard',
    CapabilityCard: 'CapabilityCard',
    dynamicSections: 'dynamicSections',
    Endcap: 'EndCap',
    gallery: 'gallery',
    hero: 'BrandHero',
    heroCarousel: 'HeroCarousel',
    medioInline: 'MedioInline',
    Newspaper: 'NewspaperModule',
    piccolaPencil: 'PiccolaPencil',
    productShelf: 'ProductShelf',
    resource: 'Resource',
    sideNavigation: 'SideNavigation',
    slot: 'Slot',
    specialBuyOfTheDay: 'SpecialBuyOfTheDay',
    spotlight: 'Spotlight',
    spotlightContainer: 'SpotlightContainer',
    spotlightRichTextSection: 'spotlightRichTextSection',
    visualNavigation: 'VisualNavigation',
    Description_New: 'Description_New'
  };

  const contentfulKeys = Object.keys(contentfulKeyToContentKeyMap);

  return {
    getComponentMap: () => {
      return contentfulTypeToComponentMap;
    },
    getComponent: (contentfulType) => {
      return contentfulTypeToComponentMap[contentfulType];
    },
    isValidKey: (contentfulKey) => {
      return contentfulKeys.includes(contentfulKey);
    },
    getContentKey: (contentfulKey) => {
      return contentfulKeyToContentKeyMap[contentfulKey];
    }
  };
};

export const componentMapper = buildComponentMapperObj();
