import thdCustomer from '@thd-olt-global/thd-customer';
import { asyncCallWithTimeout, TIMEOUT_MESSAGE } from './asyncTimeout';

export const getVariationsFromDE = async ({ campaignIds, channel, isConsumerApp }) => {

  const hitTimeGmt = new Date(Math.round(Date.now() / 1000)).getTime();
  const mcvisid = thdCustomer.mcvisID;
  const svocId = thdCustomer.svocID;

  const init = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      hitTimeGmt,
      mcvisid,
      svocId,
    }),
  };

  const uniqueCampaignIds = campaignIds.reduce((acc, campaignObj) => {
    if (!acc.find((cObj) => cObj.campaignId === campaignObj.campaignId)) {
      acc.push(campaignObj);
    }

    return acc;
  }, []);

  const ids = await Promise.allSettled(
    uniqueCampaignIds.map(async ({ campaignId, channel: campaignChannel }) => {
      const consumerAppCampaign = isConsumerApp && campaignChannel === 'consumerApp';
      const mobileOnly = campaignChannel === 'mobile';
      const desktopOnly = campaignChannel === 'desktop';
      const mobileCampaign = channel === 'mobile' && mobileOnly;
      const desktopCampaign = channel === 'desktop' && desktopOnly;
      const allChannels = !mobileOnly && !desktopOnly;

      if (mobileCampaign || desktopCampaign || allChannels) {
        const req = await asyncCallWithTimeout(fetch(`/decision-engine/campaign/${campaignId}`, init), 5000);
        const data = await req.json();
        return data.experience;
      }

      return null;
    })
  );

  const rejected = ids.filter(({ status }) => status === 'rejected').map(({ reason }) => reason);

  if (rejected.length > 0) {
    rejected.forEach((error) => {
      if (error.message === TIMEOUT_MESSAGE) {
        window.LIFE_CYCLE_EVENT_BUS.trigger(
          'component-selector.timeout',
          { source: 'Decision Engine', error: error.message, payload: { body: init.body, ids: uniqueCampaignIds } }
        );
      } else {
        window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.deError',
          { error: { status: error?.errorResponse?.status, message: error?.errorMessage } });
      }
    });
  }

  return ids
    .filter(({ status }) => status === 'fulfilled')
    .filter(({ value }) => !!value)
    .map(({ value }) => JSON.parse(value))
    .flat();
};