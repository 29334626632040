/* eslint-disable react/prop-types */
import React from 'react';
import {
  ProductPod,
  ProductATC,
  ProductRating,
  ProductIdentifier, ProductBadge, ProductSponsored,
  ProductImage, PodFooter, PodSection,
  PodSpacer
} from '@thd-olt-component-react/product-pod';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { extend } from '@thd-nucleus/data-sources';

const CustomProductPod = ({ itemId, storeId, idx }) => {
  const analyticsData = {
    parent: 'product-shelf',
    position: idx
  };

  return (
    <ProductPod
      itemId={itemId}
      analyticsData={analyticsData}
      storeId={storeId}
      padding="sui-p-2"
      render={(pod) => (
        <>
          <div className="sui-flex sui-items-center sui-justify-between">
            <PodSection noPadding>
              <ProductBadge itemId={pod.itemId} storeId={pod.storeId} />
              <ProductSponsored itemId={pod.itemId} position={idx} />
            </PodSection>
            <AddToList itemId={pod.itemId} showIconButton />
          </div>
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
            onClick={() => {}}
          />
          <PodSection columnAlign alignTop noPadding>
            <SuperSkuMiniSwatches
              itemId={pod.itemId}
              onChange={pod.onChange}
              onHover={pod.onSSKUHover}
            />
            <ProductRating itemId={pod.itemId} />
            <PodSpacer minHeight="21px">
              <ProductIdentifier itemId={pod.itemId} type="model" />
            </PodSpacer>
            <PodSpacer padding={['TOP', 'BOTTOM']}>
              <ProductHeader
                brand="inline"
                itemId={pod.itemId}
              />
            </PodSpacer>
          </PodSection>
          <PodFooter noPadding>
            <PodSpacer minHeight="60px" disable={pod.productSubType === 'HDQC'}>
              <Price
                itemId={pod.itemId}
                large={false}
                storeId={pod.storeId}
                displayEachUom={false}
                onSavingsCenterToggle={pod.onSavingsCenterToggle}
                hideForProductTypes={['HDQC']}
              />
            </PodSpacer>
            <PodSpacer minHeight="40px" padding={['TOP']}>
              <ProductATC
                itemId={pod.itemId}
                storeId={pod.storeId}
                checkGeneric
                outline
              />
            </PodSpacer>
          </PodFooter>
        </>
      )}
    />
  );
};

CustomProductPod.dataModel = extend(
  AddToList,
  Price,
  ProductATC,
  ProductBadge,
  ProductHeader,
  ProductIdentifier,
  ProductImage,
  ProductPod,
  ProductRating,
  SuperSkuMiniSwatches
);

export { CustomProductPod };
