import { LinkTag } from '@thd-nucleus/thd-helmet';
import { NTT_PREFIX } from '../../constants';

export const getBrowseAndSearchItemIds = (products = []) => {
  return products.map(({ itemId }) => itemId);
};

export const limitProductsToDisplay = ({ products = [], setLimit = false, limitCount = 12 }) => {
  if (setLimit) { return products.slice(0, limitCount); }
  return products;
};

export const getContentfulPreviewCustomerType = (pathname) => {
  if (typeof pathname === 'string') {
    const customerTypeMatch = pathname.match(/b2[b|c]$/g);
    return customerTypeMatch[0] || '';
  }
  return '';
};

export const getContentfulPreviewPathName = (pathname) => {
  if (typeof pathname === 'string') {
    return pathname.replace(/-b2[b|c]$/, '');
  }
  return '';
};

// Impressions Data
export const impProductPodData = (layoutID) => {
  return {
    id: layoutID || '',
    component: 'ResultsWrapped',
    name: 'ProductListing',
    type: 'product'
  };
};

export const impPLPRecs = (layoutID) => {
  return {
    id: layoutID || '',
    component: 'DynamicRecs',
    type: 'product'
  };
};

// Appends Image Manager query params to request a more appropriate sized hero image size.
const akamaiImageResize = (imageUrlToTransform) => {
  // Specific to Hero image size.
  const queryParams = 'Resize=(920,575)';

  if (!imageUrlToTransform) {
    return imageUrlToTransform;
  }
  const isAkamaiHost = imageUrlToTransform.includes('thdstatic.com');
  if (!isAkamaiHost) {
    return imageUrlToTransform;
  }
  if (!imageUrlToTransform.includes('?')) {
    return `${imageUrlToTransform}?im=${queryParams}`;
  }
  if (imageUrlToTransform.includes('im=')) {
    const urlBeforeImParams = imageUrlToTransform.substring(0, imageUrlToTransform.indexOf('im='));
    const imAndRemainderParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
    const hasAdditionalParams = imAndRemainderParams.includes('&');
    if (hasAdditionalParams) {
      const remainderOfParams = imAndRemainderParams.substring(imAndRemainderParams.indexOf('&'));
      const imParams = imAndRemainderParams.substring(0, imAndRemainderParams.indexOf('&'));
      return `${urlBeforeImParams}${imParams};${queryParams}${remainderOfParams}`;
    }
    return `${imageUrlToTransform};${queryParams}`;
  }
  return `${imageUrlToTransform}&im=${queryParams}`;
};

// To help lower LCP, if the heroCarousel is configured in Contentful, try to determine which image will be rendered
// to the top of the page. Once determine, add that URL to the head of the page so it'll load as soon as the page
// loads.
export const imagePreloader = ({ data: contentData }) => {
  const heroCarousel = contentData?.layouts?.content?.heroCarousel;
  if (!heroCarousel) {
    return [];
  }

  const firstComponent = heroCarousel.components[0];
  const componentType = firstComponent.__typename;
  let componentWithImage = firstComponent;
  if (componentType === 'ComponentSelector') {
    let { defaultComponent } = firstComponent;
    if (defaultComponent.__typename === 'Slot') {
      componentWithImage = defaultComponent.content;
    } else {
      componentWithImage = defaultComponent;
    }
  }

  const imageUrlToPreload = (componentWithImage?.previewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl
    || componentWithImage?.previewImage?.damDownloadedContent?.url
    || componentWithImage?.image?.url);

  if (!imageUrlToPreload) {
    return [];
  }

  const imageUrlToPreloadWithParams = akamaiImageResize(imageUrlToPreload);

  const tags = [
    new LinkTag({
      rel: 'preload', fetchPriority: 'high', href: imageUrlToPreloadWithParams, id: 'preloadFirstImg', as: 'image'
    })
  ];

  return tags;
};

export const isRefinedPage = (path) => {
  return path.includes(NTT_PREFIX) || false;
};
