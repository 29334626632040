import React, { useEffect, useRef } from 'react';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import {
  string, arrayOf, shape, bool, oneOfType, number
} from 'prop-types';
import { ComponentSelectorWrapper } from './ComponentSelectorWrapper';
import { createComponentProps } from '../utils/createComponentProps';

export function ComponentSelector({
  backgroundColor,
  componentId: csId,
  componentMap,
  defaultComponent,
  isSeoBot,
  renderDefault,
  usePlaceholder,
  variationIds,
  variations,
  targetingType,
  customPropMap
}) {
  const componentSelectorTimerRef = useRef(new Date());

  let contentType = 'content';

  useEffect(() => {
    if (window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.ready');
    }
  }, []);

  const publishEvent = ({ ...props }) => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      const start = componentSelectorTimerRef.current.getTime();
      const end = new Date().getTime();
      const loadTime = end - start;
      window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.selected', { ...props, loadTime });
    }
  };

  const isSSR = typeof window === 'undefined';
  const noVariations = variationIds === null || variationIds?.length < 1;

  const { slot: defaultSlot, props: defaultProps } = createComponentProps({ defaultComponent, customPropMap });

  const DefaultComponent = componentMap[defaultProps.componentClass];

  if (isSSR) {
    return (
      <ComponentSelectorWrapper
        backgroundColor={backgroundColor}
        isVisible={!isSeoBot}
        usePlaceholder={usePlaceholder}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {DefaultComponent && <DefaultComponent {...defaultProps} />}
      </ComponentSelectorWrapper>
    );
  }
  // This is for the initial render client-side, before the useEffect kicks in
  if (!renderDefault && noVariations) {
    return (
      <ComponentSelectorWrapper
        backgroundColor={backgroundColor}
        isVisible
        usePlaceholder={usePlaceholder}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {DefaultComponent && <DefaultComponent {...defaultProps} />}
      </ComponentSelectorWrapper>
    );
  }
  // Skip searching through the variations if there were no offers returned from Adobe and render the default
  if (renderDefault) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.default-render', { renderDefault });

    const { componentId, componentName } = defaultProps;

    publishEvent({
      renderDefault,
      isVariation: false,
      componentName,
      componentId,
      isSSR,
      isSeoBot,
      noVariations,
    });

    return (
      <ComponentSelectorWrapper backgroundColor={backgroundColor}>
        {DefaultComponent && (
          <ImpressionProvider
            data={{
              ...defaultSlot,
              id: csId,
              component: defaultProps.componentClass,
              name: 'Component Selector',
              type: contentType,
            }}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <DefaultComponent {...defaultProps} />
          </ImpressionProvider>
        )}
      </ComponentSelectorWrapper>
    );
  }

  const mbox = targetingType ? targetingType.mbox : null;
  const selectorUniqueID = mbox || csId;
  /**
   * Filter out the variationIds to ones that have a matching uniqueID to the mbox/componentId for
   * the selector, or return the whole unfiltered list if there isn't a mbox/componentId
   */
  const filteredIds = variationIds
    .filter(({ uniqueID }) => (selectorUniqueID ? selectorUniqueID === uniqueID : true)
    );

  const variation = variations.find(
    ({ slotNumber, id }) => !!filteredIds?.find(({ id: variationId, slotNumber: filteredSlotNumber }) => {
      const isSlot = !!slotNumber;

      // TODO: Backwards compatible with pre-Slot components, but is it still needed?
      if (isSlot) {
        return slotNumber === (mbox ? variationId : filteredSlotNumber);
      }
      return id === variationId;
    })
  );

  const { slot: variationSlot, props: componentProps } = createComponentProps({
    defaultComponent,
    variation,
    customPropMap
  });

  const Component = componentMap[componentProps.componentClass];

  contentType = variation ? 'content-variation' : 'content';

  const { componentName, componentId } = componentProps;

  let armId;

  if (variation) {
    armId = filteredIds.find(({
      slotNumber, id
    }) => (variationSlot.slotNumber ? (slotNumber === variationSlot.slotNumber) : (variation.id === id))
    )?.armId;
  }

  if (contentType === 'content-variation') {
    window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.variation-render', { componentId });
  } else {
    window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.default-render', {
      renderDefault: true,
    });
  }

  publishEvent({
    renderDefault,
    isVariation: !!variation,
    componentName,
    componentId,
    isSSR,
    isSeoBot,
    noVariations,
    variations: variations || [],
    variationIds: variationIds || [],
  });

  return (
    <ComponentSelectorWrapper backgroundColor={backgroundColor}>
      {Component && (
        <ImpressionProvider
          data={{
            ...variationSlot,
            id: csId,
            component: 'Component Selector',
            name: 'Component Selector',
            type: contentType,
            armId
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...componentProps} />
        </ImpressionProvider>
      )}
    </ComponentSelectorWrapper>
  );
}

ComponentSelector.displayName = 'ComponentSelector';

ComponentSelector.propTypes = {
  backgroundColor: string,
  componentId: string,
  componentMap: shape({}),
  customPropMap: shape({}),
  defaultComponent: shape({}).isRequired,
  isSeoBot: bool,
  renderDefault: bool,
  usePlaceholder: bool,
  variationIds: arrayOf(
    shape({
      provider: string.isRequired,
      id: oneOfType([string, number]),
      mbox: string,
      componentID: string,
      uniqueID: oneOfType([string, number]),
      slotNumber: number,
    })
  ),
  variations: arrayOf(shape({})).isRequired,
  targetingType: shape({ mbox: string })
};

ComponentSelector.defaultProps = {
  backgroundColor: 'white',
  componentId: '',
  componentMap: {},
  isSeoBot: false,
  renderDefault: false,
  usePlaceholder: true,
  variationIds: [],
  targetingType: {},
  customPropMap: {}
};
