import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  useBreakpoint,
  Pagination,
  PaginationItem,
  PaginationCompact,
  PaginationDisplayedItemCount
} from '@one-thd/sui-atomic-components';
import { updateQueryParams } from '../product-results-helpers';
import { ProductResultsContext } from './ProductResultsContext';

import '../styles/results-pagination.scss';

const ResultsPagination = (props) => {
  const { data } = useContext(ProductResultsContext);
  const matches = useBreakpoint('sm');

  const { onPageChange, pageSize, path } = props;

  const { products = [], searchReport } = data?.searchModel || {};
  if (!products.length || !searchReport) return null;

  const totalProducts = searchReport?.totalProducts > 696
    ? 696
    : searchReport?.totalProducts;

  const actualProductCount = searchReport?.totalProducts;
  const startIndex = searchReport?.startIndex || 0;

  const currentPage = Math.round(startIndex / pageSize) + 1;
  const totalPages = Math.ceil(totalProducts / pageSize);

  if (Number.isNaN(totalPages)
  || Number.isNaN(pageSize)
  || totalPages === 1
  || totalPages === Infinity) return null;

  const onChangeWrapper = (event, page) => {
    document.body.classList.add('results-pagination--fade');
    setTimeout(() => {
      event.preventDefault();
      if (onPageChange) {
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('resetImpressionProvider');
        const url = updateQueryParams(path, [
          { paramName: 'Nao', paramValue: (page - 1) * pageSize }
        ]);
        onPageChange({ page, searchReport, url });
      }
      document.body.classList.remove('results-pagination--fade');
    }, 0);
  };

  const updateNao = (index = 0) => {
    let [_path, queryString = ''] = path.split('?');

    const isFirstPage = pageSize / index === pageSize;
    const multiplier = pageSize * (index - 1);

    // If a queryString exists and Nao is not the first param, an '&' will be required
    const amp = (queryString && !queryString.startsWith('Nao=')) ? '&' : '';

    // Nao is only needed on pages beyond the first
    const nao = (!isFirstPage && index > 0) ? `${amp}Nao=${multiplier}` : '';

    // If Nao is already present, it should be updated in place, otherwise append it
    queryString = queryString.includes('Nao=') ? queryString.replace(/&*Nao=[\w]+/, nao) : queryString.concat(nao);

    // Replace any duplicated '&' and remove any leading or trailing '&'
    // This ensures SEO and analytics friendly URLs
    queryString = queryString.replace(/&{2,}/g, '&').replace(/^&+|&+$/g, '');

    return `${_path}${queryString ? `?${queryString}` : ''}`;
  };

  const getPaginationItem = (item) => {
    // prevent last page being an anchor tag
    if (item.page === totalPages) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <PaginationItem {...item} />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PaginationItem {...item} href={updateNao(item.page)} />;
  };

  return (
    <div className="sui-grow sui-pl-3" data-component="ResultsPagination">
      <div className="sui-relative
        sui-flex
        sui-items-center
        sui-gap-4
        sui-flex-col-reverse
        sm:sui-flex-row
        sm:sui-gap-0"
      >
        <PaginationDisplayedItemCount
          page={currentPage}
          itemsPerPage={pageSize}
          items={actualProductCount}
        />
        {matches.lesser
          ? (
            <PaginationCompact
              count={totalPages}
              page={currentPage}
              onChange={onChangeWrapper}
              fullWidth={matches.lesser}
              renderItem={getPaginationItem}
            />
          )
          : (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={onChangeWrapper}
              renderItem={getPaginationItem}
            />
          )}
      </div>
    </div>
  );
};

ResultsPagination.defaultProps = {
  pageSize: 24,
  path: ''
};

ResultsPagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  path: PropTypes.string
};

ResultsPagination.displayName = 'ResultsPagination';

export { ResultsPagination };
