/**
 * @function formatComponentIds
 * @description Takes variation ID responses from various sources and formats them into a structure the
 * ComponentSelector can use. The important thing is attaching a "uniqueID" property that can be used to
 * filter certain IDs to only ones that should be used for a specific ComponentSelector.
 * @param {Object} idsObject object that holds lists of variation IDs from Adobe, Decision Engine, Innervate or the URL.
 * As of now, the genericContent is not used.
 * { adobeVariationIds,
 *  decisionEngineVariationIds,
 * urlVariationIds,
 * genericContent,
 * innervateVariationIds }
 * @returns A merged array of formatted variationIds from various sources.
 */
export const formatComponentIds = ({
  adobeVariationIds = [],
  decisionEngineVariationIds = [],
  innervateVariationIds = [],
  urlVariationIds = [],
  genericContent = []
}) => {
  // TODO: change "id" to be slot number? Has to happen in getVariationsFromAdobe too
  const adobeIds = adobeVariationIds
    .filter(({ id }) => !!id)
    .map(({ id, mbox }) => ({ id, provider: 'adobe', mbox, uniqueID: mbox }));

  const decisionEngineIds = decisionEngineVariationIds
    .filter(({ componentID }) => !!componentID)
    .map(({ slot, componentID, ...rest }) => (
      {
        ...rest,
        componentID,
        slotNumber: slot,
        provider: 'de',
        uniqueID: componentID
      }
    ));

  const innervateIds = innervateVariationIds
    .filter((obj) => !!obj.value)
    .map(({ value }) => (
      {
        ...value[0],
        uniqueID: value[0].componentSelectorID,
        provider: 'innervate'
      }
    ));

  const urlIDs = urlVariationIds.filter((id) => !!id).map((id) => ({ id, provider: 'queryparam' }));

  const genericComponentIds = genericContent.map((obj) => ({ id: obj.id, provider: 'generic' }));

  const allIds = adobeIds.concat(decisionEngineIds, urlIDs, genericComponentIds, innervateIds);

  return allIds;
};