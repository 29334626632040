import React, { useEffect, useMemo } from 'react';

import {
  arrayOf,
  customType,
  fragment,
  number as numberType,
  params,
  shape,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { Image } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { string, bool, number } from 'prop-types';
import { useImpression } from '~/@thd-olt-component-react/impression/dist/impression';

import './brand-hero.scss';
import { BLACK_CTA_BTN_CLASSES, COLORS, ORANGE_CTA_BTN_CLASSES, WHITE_CTA_BTN_CLASSES } from '../constants';
import { getTarget } from '../utils';

const BrandHero = ({ componentId, componentClass, withSideNav, componentPosition }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('hero.ready');
  }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  const {
    eyebrow,
    title,
    description,
    cta,
    link,
    previewImage,
    textColor,
    buttonColor,
    logoOrBadge,
    altText,
    altTextForLogoBadge,
  } = data?.component || {};

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'HeroFlattenImage',
      component: componentClass,
      position: componentPosition,
      type: 'content',
    },
  });

  const backgroundColor = data?.component?.backgroundColor || COLORS.white;

  const heroImgSrc = previewImage?.damDownloadedContent?.url
    || previewImage?.damContentSelector?.assetData[0]?.selectedImageUrl;

  const brandLogoSrc = logoOrBadge?.damDownloadedContent?.url
    || logoOrBadge?.damContentSelector?.assetData[0]?.selectedImageUrl;

  const colourRegex = /#[0-9A-F]{6}/;
  const textColorHex = textColor?.match(colourRegex)[0] || COLORS.black;
  const ctaBackgroundColorHex = buttonColor?.match(colourRegex)[0] || COLORS.orange;

  const buttonColourStyles = useMemo(() => {
    return {
      [ORANGE_CTA_BTN_CLASSES]: ctaBackgroundColorHex === COLORS.orange,
      [BLACK_CTA_BTN_CLASSES]: ctaBackgroundColorHex === COLORS.black,
      [WHITE_CTA_BTN_CLASSES]: ctaBackgroundColorHex === COLORS.white,
    };
  }, [ctaBackgroundColorHex]);

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
      id={`${componentClass}-${componentId}`}
      data-component-id={componentId}
      data-component-name={componentClass}
      className={classNames(
        'brand-hero__container sui-w-full sui-h-full'
      )}
    >
      <div
        className={
          classNames('brand-hero__info-box sui-max-w-full sui-gap-2',
            'sui-flex sui-flex-col sui-justify-center sui-p-8 xl:sui-p-6',
            { 'brand-hero-w-sidenav__info-box': withSideNav }
          )
        }
        style={{
          color: textColorHex,
          backgroundColor,
        }}
      >
        <p className="sui-text-base sui-font-bold">{eyebrow}</p>
        {
          logoOrBadge
          && (
            <Image
              className={classNames(
                'brand-hero__logo sui-w-auto sui-max-h-12 sui-object-contain sui-object-left'
              )}
              alt={altTextForLogoBadge}
              src={brandLogoSrc}
            />
          )
        }
        <h2
          className="sui-h2-display sui-uppercase"
          style={{
            color: textColorHex,
          }}
        >
          {title}
        </h2>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <p className="brand-hero__subtitle sui-text-base sui-font-normal">{description}</p>
        {cta && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={link}
            target={getTarget(link)}
            className={classNames(
              'sui-btn sui-w-fit sui-font-bold sui-text-base',
              'sui-leading-normal sui-tracking-normal sui-normal-case',
              'sui-line-clamp-unset sui-btn-primary sui-btn-focus sui-mt-2 link--hover-no-underline',
              buttonColourStyles,
            )}
          >
            {cta}
          </a>
        )}
      </div>

      <Image
        className={classNames(
          'sui-h-auto sui-w-full sui-object-cover sui-aspect-video sui-row-start-1',
          'md:sui-aspect-square md:sui-w-auto md:sui-h-full brand-hero__image',
          { 'lg:sui-aspect-square 2xl:sui-aspect-video': withSideNav }
        )}
        alt={altText}
        src={heroImgSrc}
      />
    </div>
  );
};

BrandHero.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  withSideNav: bool,
};

BrandHero.defaultProps = {
  withSideNav: undefined,
  componentPosition: 1,
};

BrandHero.displayName = 'BrandHero';

BrandHero.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Hero']).isRequired(),
  }).shape({
    Hero: fragment().shape({
      id: stringType(),
      eyebrow: stringType(),
      title: stringType(),
      description: stringType(),
      cta: stringType(),
      link: stringType(),
      altText: stringType(),
      altTextForLogoBadge: stringType(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType(),
            })
          ),
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType(),
        }),
      }),
      backgroundColor: stringType(),
      // textColor is a required field
      textColor: stringType(),
      // buttonColor is a required field
      buttonColor: stringType(),
      logoOrBadge: shape({
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType(),
            })
          ),
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType(),
        }),
      }),
    }),
  }),
};

export { BrandHero };
