import React, { useEffect } from 'react';
import { bool as boolean, number as numberType, string as stringType } from 'prop-types';
import {
  arrayOf, customType, fragment, params, shape, string, useDataModel
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { BannerRenderer } from './BannerRenderer';
import { withImpressions } from '../hocs/withImpressions';

const MedioInline = withImpressions(({ componentClass, componentId, componentPosition, lazyLoad }) => {

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: MedioInline.displayName,
      component: MedioInline.displayName,
      position: componentPosition,
      type: 'content'
    }
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('medio-inline.ready');
  }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      componentClass,
      id: componentId
    }
  });

  return (
    <div
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
      data-component-id={componentId}
      data-component-name="MedioInline"
      data-component-position={componentPosition}
      data-component="MedioInline"
      id={`${componentClass}-${componentPosition}-${componentId}`}
      ref={ref}
    >
      <BannerRenderer
        data={data}
        mobileAspectRatio="sui-aspect-[640/241]"
        tabletAspectRatio="md:sui-aspect-[142/15]"
        desktopAspectRatio="xl:sui-aspect-[142/15]"
        lazyLoad={lazyLoad}
      />
    </div>
  );
});

MedioInline.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  componentPosition: numberType,
  lazyLoad: boolean
};

MedioInline.defaultProps = {
  componentClass: '',
  componentPosition: 1
};

MedioInline.displayName = 'MedioInline';

MedioInline.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['MedioInline']).isRequired()
  }).shape({
    MedioInline: fragment().shape({
      componentName: string(),
      id: string(),
      link: string(),
      altText: string(),
      desktopImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(shape({
            selectedImageUrl: string()
          }))
        }),
        damDownloadedContent: shape({
          url: string()
        })
      }),
      mobileImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(shape({
            selectedImageUrl: string()
          }))
        }),
        damDownloadedContent: shape({
          url: string()
        })
      }),
      tabletImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(shape({
            selectedImageUrl: string()
          }))
        }),
        damDownloadedContent: shape({
          url: string()
        })
      })
    })
  })
};

export { MedioInline };