import { asyncCallWithTimeout, TIMEOUT_MESSAGE } from './asyncTimeout';

export const getVariationsFromAdobe = async (mboxes) => {
  try {
    /* eslint-disable-next-line no-undef */
    const adobeMboxIds = await asyncCallWithTimeout(adobe.target.getOffers({
      consumerId: mboxes.join(', '),
      request: {
        execute: {
          mboxes: mboxes.map((mbox, index) => ({ name: mbox, index }))
        }
      },
      timeout: 5000
    }), 5000);

    const ids = adobeMboxIds?.execute?.mboxes?.map(({ options, name }) => {
      const opts = options ? options[0] : null;
      const content = opts && opts.content ? opts.content[0] : null;
      const id = content && content.variationsIds ? content.variationsIds[0] : null;

      return {
        id,
        mbox: name
      };
    }) || [];

    // new code for updated triggerLocations
    // const ids = adobeMboxIds?.execute?.mboxes?.map(({ content, name }) => {
    //   const data = content[0];
    //   const id = data && data.variationsIds ? data.variationsIds[0] : null;

    //   return {
    //     id,
    //     mbox: name
    //   };
    // }) || [];

    return ids;
  } catch (error) {
    if (error.message === TIMEOUT_MESSAGE) {
      window.LIFE_CYCLE_EVENT_BUS.trigger(
        'component-selector.timeout',
        { source: 'Adobe Target', error: error.message, payload: { body: {}, ids: mboxes } }
      );
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.adobeError',
        { error: { message: error?.message, stack: error?.stack } });
    }
    /* eslint-disable-next-line no-console, no-unused-expressions */
    window.isDebugMode && console.error(error);
    return [];
  }
};