/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import {
  string, shape, arrayOf, func
} from 'prop-types';
import {
  DrawerItem, DrawerHeader, DrawerFooter
} from '@thd-olt-component-react/drawer';
import { Button, Row, Col } from '@thd-olt-component-react/core-ui';
import { FitCompatibility } from '@thd-olt-component-react/fit-compatibility';
import classNames from 'classnames/bind';
import styles from '../../styles/primary-filters-drawer.scss';
import {
  getRefinementsByDimension,
  isRefinementActive,
  sortSelectedDimensions,
  sortRefinementKeys,
  sortAscending,
  findCustomPriceRange,
  getDimensionsForUrl,
  getRefinemntsLabelsForUrl,
  filtersCatStyleCheck,
  sortOrder
} from '../../product-results-helpers';
import { DrawerRefinement } from './DrawerRefinement';
import { MultiStateDrawerRefinement } from './MultiStateDrawerRefinement';
import { publish } from '../../publisher';
import { PriceRangeRefinement } from '../Refinements/PriceRangeRefinement';

const PrimaryFilterDrawerDimension = ({
  dimension,
  appliedDimensions,
  onCancel,
  onDimensionChange,
  baseUrl,
  canonicalUrl
}) => {
  const cx = classNames.bind(styles);
  const activeSelectedRefinements = appliedDimensions ? getRefinementsByDimension(appliedDimensions, dimension) : [];
  let [selectedRefinements, setSelectedRefinements] = useState(activeSelectedRefinements);

  const [revision, setRevision] = useState(0);
  const [customRangeEntered, setCustomRangeEntered] = useState(0);

  const buttonLabel = activeSelectedRefinements?.length > 0 ? 'Update' : 'Apply';

  const [lowerBound, setLowerBound] = useState('');
  const [upperBound, setUpperBound] = useState('');

  const isLowerBoundEntered = (value) => setLowerBound(value);

  const isUpperBoundEntered = (value) => setUpperBound(value);

  const desktopSort = useRef('');
  const handleSortOrderChange = (sortorder) => {
    if (sortorder) {
      desktopSort.current = `${sortorder.output.sortby}:${sortorder.output.sortorder}`;
    }
  };

  useEffect(() => {
    setSelectedRefinements(getRefinementsByDimension(appliedDimensions, dimension));
    window.LIFE_CYCLE_EVENT_BUS.on('sortorder.value', handleSortOrderChange);
  }, [appliedDimensions, desktopSort]);

  const onRefinementChange = ({ refinement }) => {
    // verify whether or not the refinement is on the list
    let index = refinement?.dimension?.dimensionId === 'custom_price'
      ? -1 : selectedRefinements.findIndex((ref) => ref.refinementKey === refinement.refinementKey);

    // coppy current list
    let temp = Array.isArray(selectedRefinements) ? selectedRefinements.slice() : [];
    if (index >= 0) {
      // if already in the list, remove it
      temp.splice(index, 1);
    } else {
      // if not in the list, add it
      temp.push(refinement);
    }
    setSelectedRefinements(temp);
  };

  const clearInputFields = () => {
    setLowerBound('');
    setUpperBound('');
    setRevision(revision + 1);
  };

  const handleOnApply = () => {
    document.body.classList.add('filter-and-sort--fade');
    setTimeout(() => {
      // copy current applied dimensions list
      let temp = appliedDimensions.slice();
      // check whether the dimension is in the appliedDimensions list
      const index = appliedDimensions.findIndex((ref) => {
        return ref.label === dimension.label;
      });
      // consolidate current selection and current applied dimensions
      if (index === 0) {
        temp[index].refinements = selectedRefinements;
      } else {
        temp.push({
          label: dimension.label,
          refinements: selectedRefinements
        });
      }
      // sort dimensions as per seo requirement
      const selectedimensions = temp;
      const sortedSelectedimensions = sortSelectedDimensions(selectedimensions);

      // get refinement keys and sort them by id
      let refinementKeys = sortedSelectedimensions
        .map((dim) => dim.refinements.map((ref) => ref.refinementKey)).flat(1);

      let sortedRefinementKeys = sortRefinementKeys(refinementKeys);

      if (selectedimensions && Object.keys(selectedimensions).length > 0) {
        publish('change-filters-refinements', selectedimensions);
      }
      // get dimensions that may go on the url
      let dimensionsIncludedInUrl = getDimensionsForUrl(sortedSelectedimensions);

      // get refinements from the dimensions which are added to the url
      let refinementsIncludedInUrl = dimensionsIncludedInUrl?.map(
        (_dimension) => _dimension.refinements.sort(sortAscending)).flat(1);
      let length = refinementsIncludedInUrl.length;
      let lastRefinement = refinementsIncludedInUrl[length - 1];
      let urlParts = baseUrl?.split('?')[0].split('/');

      // get custom price range
      const priceDimension = selectedimensions.find((_dimension) => _dimension.label === 'Price');
      const [lowerbound, upperbound] = findCustomPriceRange(priceDimension?.refinements);
      const canonicalUrlParts = canonicalUrl.split('?');
      let queryParams = canonicalUrlParts.length > 1 ? canonicalUrlParts[1] : '';
      if (lowerbound || upperbound) {
        let queries = queryParams.split('&')
          .filter((query) => !query.toLowerCase().includes('lowerbound') && !query.toLowerCase().includes('upperbound'));
        queryParams = queries.join('&');
        queryParams += `&lowerbound=${lowerbound}&upperbound=${upperbound}`;
      }

      queryParams = filtersCatStyleCheck(queryParams);

      queryParams = sortOrder({ desktopSort, queryParams });

      const nValue = urlParts.find((part) => part.indexOf('N-') !== -1) || '';
      let refinementsIds = nValue;
      if (sortedRefinementKeys.length > 0) {
        refinementsIds += 'Z' + sortedRefinementKeys.join('Z');
      }

      // get refinement labels for url
      const refinamentsLabelsForUrl = getRefinemntsLabelsForUrl(refinementsIncludedInUrl);
      urlParts.splice(urlParts.indexOf(nValue), 0, ...refinamentsLabelsForUrl);

      urlParts.splice(urlParts.indexOf(nValue), 1, refinementsIds);
      const url = queryParams ? urlParts.join('/') + '?' + queryParams : urlParts.join('/');
      lastRefinement = { ...lastRefinement, url };
      onDimensionChange({ refinement: lastRefinement });
      clearInputFields();
      document.body.classList.remove('filter-and-sort--fade');
    }, 0);
  };

  const hasFitCompatibility = [
    'DEPTH (EXCLUDING HANDLES) (IN.)',
    'HEIGHT TO TOP OF DOOR HINGE (IN.)',
    'HEIGHT TO TOP OF REFRIGERATOR (IN.)',
    'INSTALLATION DEPTH',
    'REFRIGERATOR FIT WIDTH',
    'TOTAL CAPACITY (CU. FT.)',
    'CAPACITY (CU. FT.) - REFRIGERATORS',
    'REFRIGERATOR CAPACITY (CU. FT.)',
    'DEPTH (INCLUDING HANDLES) (IN.)'
  ].includes(dimension.label.toUpperCase());

  const customRangeHeading = (text) => (
    <h3 className={cx('primary-filters-drawer__heading')}>{text}:</h3>
  );
  const triggerRefinementForCustomRange = () => {
    onRefinementChange({
      refinement: {
        lowerbound: lowerBound,
        upperbound: upperBound,
        urlSearchParams: window.location.search,
        label: `$${lowerBound} - $${upperBound}`,
        deselectUrl: '?',
        refinementKey: null,
        dimension: {
          dimensionId: 'custom_price',
          label: 'Price'
        }
      }
    });
  };

  // add entered custom range into refinements object
  useEffect(() => {
    if (lowerBound.length !== 0 || upperBound.length !== 0) {
      setCustomRangeEntered(1);
      triggerRefinementForCustomRange();
    } else if (lowerBound.length === 0 || upperBound.length === 0) {
      setCustomRangeEntered(0);
      setSelectedRefinements([]);
    }
  }, [lowerBound, upperBound]);

  const pfContainer = cx('primary-filters-drawer__container', {
    'primary-filters-drawer__container__ref': dimension.label !== 'Price'
  });

  return (
    <DrawerItem name={dimension.label} key={`primary-filter-item${dimension.label}`}>
      <DrawerHeader title={dimension.label} />
      <section className={cx({ 'primary-filters-drawer__wrapper': dimension.label === 'Price' })}>
        {
          dimension.label === 'Price' && (
            <>
              {customRangeHeading('Enter a custom range')}
              <PriceRangeRefinement
                dimension={dimension}
                onChange={onRefinementChange}
                isLowerBoundEntered={isLowerBoundEntered}
                isUpperBoundEntered={isUpperBoundEntered}
                revision={revision}
                drawer={false}
                multiFacet={false}
                isPrimaryFilter
              />
              {customRangeHeading('Select a range')}
            </>
          )
        }
        <div className={pfContainer}>
          {hasFitCompatibility && (
            <div
              role="button"
              tabIndex={0}
              aria-label="fit-compatibility"
              className="sui-p-1 sui-pb-0 sui-ml-1"
              onClick={onCancel}
            >
              <FitCompatibility
                type="MINI"
                drawerPosition="left"
                imageCacheEnabled
              />
            </div>
          )}
          {dimension.refinements.map((refinement) => {
            if (dimension.label === 'Category') {
              return (
                <DrawerRefinement
                  key={`primary-filters-rerfinement-${refinement.refinementKey}`}
                  refinement={refinement}
                  dimension={dimension}
                  onClick={onDimensionChange}
                />
              );
            }
            return (
              <MultiStateDrawerRefinement
                dimension={dimension}
                refinement={refinement}
                key={`primary-filters-rerfinement-${refinement.refinementKey}`}
                onClick={onRefinementChange}
                selected={isRefinementActive(refinement.refinementKey, selectedRefinements)}
              />
            );
          })}
        </div>
      </section>

      {dimension.label !== 'Category'
            && (
              <DrawerFooter>
                <Row className={cx('primary-filters-drawer__action__btn__wrapper')}>
                  <Col className={cx('primary-filters-drawer__action__btn')}>
                    <Button
                      onClick={handleOnApply}
                      disabled={
                        customRangeEntered === 0
                        && selectedRefinements.length === 0 && activeSelectedRefinements.length === 0
                      }
                    >
                      {buttonLabel}
                    </Button>
                  </Col>
                  <Col className={cx('primary-filters-drawer__action__btn')}>
                    <Button className={cx('primary-filters-drawer__action__btn__cancel')} outline onClick={onCancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </DrawerFooter>
            )}
    </DrawerItem>
  );
};

PrimaryFilterDrawerDimension.displayName = 'PrimaryFilterDrawerDimension';
PrimaryFilterDrawerDimension.propTypes = {
  dimension: shape({
    label: string
  }).isRequired,
  appliedDimensions: arrayOf(shape({})).isRequired,
  baseUrl: string.isRequired,
  canonicalUrl: string.isRequired,
  onCancel: func.isRequired,
  onDimensionChange: func.isRequired
};

export { PrimaryFilterDrawerDimension };
