/**
 * @function getContentfulTargetingIds
 * @description Loops through a Contentful response, finds the ComponentSelectors, and
 * returns all the innervateTags, campaignIds and mboxes.
 * @param {Object} contentObject object with a "content" key. Holds a Contentful data response.
 * @returns object containing innervateTags, campaignIds and mboxes {campaignIds, mboxes, innervateTags}
 */
export const getContentfulTargetingIds = ({ content }) => {
  const campaignIds = [];
  const mboxes = [];
  const innervateTags = [];

  if (content) {
    const variations = [];
    Object.keys(content).forEach((key) => {
      const componentData = content[key];
      const isDynamicSection = Array.isArray(componentData) && key !== 'breadCrumbs';
      const isContentfulComponent = !!componentData?.__typename || isDynamicSection;

      if (isContentfulComponent) {
        let components = [];
        if (isDynamicSection) {
          components = componentData.reduce((acc, sections) => [...acc, ...(sections.components || [])], []);
        } else {
          components = componentData.components || [];
        }
        const variationArr = components.filter((comp) => comp?.__typename === 'ComponentSelector');
        variations.push(...variationArr);
      }
    });

    variations.forEach((comp) => {
      const { targetingType, id } = comp;
      if (targetingType?.__typename === 'AdobeTarget') {
        mboxes.push(targetingType.mbox);
      } else if (targetingType?.__typename === 'MultiArmBandit') {
        campaignIds.push({ campaignId: targetingType.campaignId, channel: targetingType.channel });
      } else if (targetingType?.__typename === 'Innervate') {
        innervateTags.push({ tagID: targetingType.tagID, adkey: targetingType.adkey, csId: id });
      }
    });
  }

  return ({ mboxes, campaignIds, innervateTags });
};