export const createInnervateURL = ({
  tagID, adkey, isDebug, storeId, slug, csId
}) => {
  const domain = 'https://rna.homedepot.com/';
  const storeQuery = storeId ? `&thd_store_id=${storeId}` : '';
  const slugQuery = slug ? `&thd_pageid=${slug}` : '';
  const adKeyQuery = `?adkey=${adkey}`;
  const csIdQuery = `&thd_componentSelectorID=${csId}`;

  const trx = document.cookie
    .split('; ')
    .find((row) => row.startsWith('trx='))
    ?.split('=')[1];
  const vidQuery = trx ? `&vid=${trx}` : '';

  const aamID = document.cookie
    .split('; ')
    .find((row) => row.startsWith('aam_uuid='))
    ?.split('=')[1];
  const aamQuery = aamID ? `&aam_id=${aamID}` : '';

  const debugQuery = isDebug ? '&debug=emulate' : '';

  return `${domain}${tagID}${adKeyQuery}${debugQuery}${storeQuery}${slugQuery}${vidQuery}${aamQuery}${csIdQuery}`;
};