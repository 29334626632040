import { heightRE, lengthRE, widthRE } from './constants';

export const getDollars = (amount = NaN) => {
  return amount.toFixed(2);
};

export const getCents = (amount = NaN) => {
  return +amount.toFixed(2).toString().split('.')[1];
};

export const getMetadataSampleColorSpecName = (attributes) => attributes?.find((attr) => attr.isSwatch)?.attributeName;

export const getSpecValue = ({ specificationGroup, specName }) => {
  const details = specificationGroup?.find((spec) => spec.specTitle === 'Details');
  return details?.specifications?.find((spec) => spec.specName === specName)?.specValue || '';
};

const getDimensionText = ({ specificationGroup, attributeSpecNames = null }) => {
  let dimensionText = '';
  const { specifications: dimensionSpecifications } = specificationGroup
    .find((spec) => spec.specTitle === 'Dimensions') || {};
  
  if (dimensionSpecifications?.length > 0) {
    const length = dimensionSpecifications.find(({ specName }) => lengthRE.test(specName) || heightRE.test(specName));
    const width = dimensionSpecifications.find(({ specName }) => widthRE.test(specName));
    
    if (length && width) {
      const dimensions = [length, width].map((dimension) => {
        if (attributeSpecNames && attributeSpecNames.includes(dimension.specName)) {
          attributeSpecNames.splice(attributeSpecNames.indexOf(dimension.specName), 1);
        }
        const specExt = dimension.specName.match(/\(([^)]+)\)/);
        const specName = specExt.length > 0 ? specExt[1] : '';
        return `${(dimension.specValue.match(/\d+/g) || [])[0]} ${specName}`;
      });
      dimensionText = dimensions.join(' x ');
    }
  }
  return dimensionText;
};

export const getAllSampleDimensions = ({ samples }) => samples
  .map(({ specificationGroup }) => getDimensionText({ specificationGroup }));

export const getSampleAttributeValues = ({ specificationGroup, attributes, samplesHaveSameDimensions }) => {
  const attributeSpecNames = [];
  // match the sample attribute value with the attribute value from the parent
  attributes.forEach((attr) => {
    specificationGroup.forEach(({ specifications }) => {
      if (specifications.some(({ specName }) => specName === attr.attributeName)) {
        attributeSpecNames.push(attr.attributeName);
      }
    });
  });

  // !! DON'T MOVE !!
  // This splices width and length from matchingSpecs if they exist,
  // so it must be before the specificationGroup.forEach below
  const dimensionText = samplesHaveSameDimensions
    ? ''
    : getDimensionText({ specificationGroup, attributeSpecNames });

  const attributeValues = [];
  specificationGroup.forEach(({ specifications }) => {
    const matchingSpecs = specifications.filter(({ specName }) => attributeSpecNames.includes(specName));
    if (matchingSpecs.length) {
      matchingSpecs.forEach(({ specValue }) => attributeValues.push(specValue));
    }
  });

  if (dimensionText) {
    attributeValues.push(dimensionText);
  }

  let name = attributeValues.length ? attributeValues.reduce((acc, curr) => `${acc}, ${curr}`) : null;
  // fallback to Color Family if there is no proper combination name
  if (!name) {
    name = getSpecValue({ specificationGroup, specName: 'Color Family' });
  }
  return name;
};

export const getSampleColorSpecValue = (attributes, specificationGroup) => {
  const metadataSpecName = getMetadataSampleColorSpecName(attributes);
  return getSpecValue({
    specificationGroup,
    specName: metadataSpecName
  });
};

export const getMetadataAttributeValues = (attributes) => {
  const metadataSpecName = getMetadataSampleColorSpecName(attributes);
  return attributes.find((attr) => attr.attributeName === metadataSpecName)
    ?.attributeValues?.map((attrValue) => attrValue.value) || [];
};

export const normalizeImageUrl = (url, size) => {
  const urlRE = /<SIZE>/i;
  return url.replace(urlRE, size);
};

const getMatchingChildItemId = ({ childItems, specificSkuName, excludedItemIds }) => {
  return childItems
    .find((childItem) => {
      return !!childItem.itemId
        && !excludedItemIds.includes(childItem.itemId)
        && childItem.attributeCombination.split('~~').includes(specificSkuName);
    })?.itemId;
};
const getFilteredChildItemIds = ({ childItems, specificSkuNames, excludedItemIds }) => {
  return childItems
    .filter((childItem) => {
      if (!!childItem.itemId && !excludedItemIds.includes(childItem.itemId)) {
        return specificSkuNames.filter((specificSkuName) => {
          return childItem.attributeCombination.split('~~').includes(specificSkuName);
        }).length;
      }
      return false;
    })
    .map((childItem) => childItem.itemId);
};

export const getAnchorItemIds = ({ metadata, specificSkuNames = [], excludedItemIds = [] }) => {
  if (metadata) {
    const childItems = metadata.childItemsLookup || [];
    const attributes = metadata.attributes || [];
    const skuNames = getMetadataAttributeValues(attributes)
      .filter((value) => !specificSkuNames.length || specificSkuNames.includes(value));
      
    if (childItems.length) {
      if (skuNames.length) {
        const tempItemIds = [];
        skuNames.forEach((skuName) => {
          // eslint-disable-next-line max-len
          const childItemsWithSkuName = getMatchingChildItemId({ childItems, specificSkuName: skuName, excludedItemIds });
          if (childItemsWithSkuName) {
            tempItemIds.push(childItemsWithSkuName);
          }
        });
        return tempItemIds;
      }
      return getFilteredChildItemIds({ childItems, specificSkuNames, excludedItemIds });
    }
  }
  return [];
};

export const getUncalledUniqueSampleIdsFromProducts = (products) => {
  return [...new Set(products.map((product) => {
    return product?.identifiers?.sampleId;
  }).filter((sampleId) => {
    return !!sampleId;
  }))];
};